/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Reset css with normalize
import '@/styles/normalize.css'

// Import global style
import '@/styles/global.css'

// You can delete this file if you're not using it
if (/windows/i.test(navigator.userAgent)) {
  require('./src/styles/font-window.css')
} else {
  require('./src/styles/font-mac.css')
}
